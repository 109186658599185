import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    setPageSettings: ['data'],
    toogleMenu: [],
  },
  {
    prefix: "@page/",
  }
);