import React, { useEffect } from 'react'
import { Field, reduxForm, FormSection } from 'redux-form';
import { Button,Row,Col } from 'reactstrap';
import { SimpleInput } from '../components/FormInputs';

const validate = values => {
    const errors = {}
    // if (!values.nome) 
    //     errors.nome = 'Obrigatório';
    return errors;
}

let FiltroLista = props => {

    useEffect(() => {
        if(props.initialValue)
            props.initialize(props.initialValue)
    }, [props]);

    const { handleSubmit, pristine, submitting, invalid } = props
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col><Field name="search" type="search" component={SimpleInput} label="Buscar" /></Col>
                <Col><Field name="from" type="date" component={SimpleInput} label="De" /></Col>
                <Col><Field name="to" type="date" component={SimpleInput} label="Até" /></Col>
                <Col><Button type="submit">Filtrar</Button></Col>
                
            </Row>
        </form>
    )
}

export default reduxForm({
  form: 'FiltroLista',
  validate
})(FiltroLista)