import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";

class Camera extends Component {

    state = {people:{}}

    componentDidMount() {

        // BASIC THREE.JS THINGS: SCENE, CAMERA, RENDERER
        // Three.js Creating a scene tutorial
        // https://threejs.org/docs/index.html#manual/en/introduction/Creating-a-scene
        const loader = new THREE.TextureLoader();
        this.scene = new THREE.Scene();
        this.camera = new THREE.OrthographicCamera( 640 / - 2, 640 / 2, 480 / 2, 480 / - 2, 1, 1000 );

        this.camera.position.z = 5;

        this.group = new THREE.Group();
        this.scene.add( this.group );

        this.renderer = new THREE.WebGLRenderer();
        
        let clientWidth = document.getElementById('block-comodo').clientWidth;

        this.renderer.setSize(clientWidth, clientWidth/1.33);

        if (this.props.background)
            loader.load(this.props.background , (texture) => {
                this.scene.background = texture;  
            });

        // MOUNT INSIDE OF REACT
        this.mount.appendChild(this.renderer.domElement); // mount a scene inside of React using a ref



        // ADD CUBE AND LIGHTS
        // https://threejs.org/docs/index.html#api/en/geometries/BoxGeometry
        // https://threejs.org/docs/scenes/geometry-browser.html#BoxGeometry
        this.geometry = new THREE.SphereGeometry(5, 5, 5);
        this.material = new THREE.MeshPhongMaterial( {
            color: 'red',
            emissive: 'red',
            side: THREE.DoubleSide,
            flatShading: true
        } );
        const lineMaterial = new THREE.LineBasicMaterial( { color: 0x0000ff } );

        // this.cube = new THREE.Mesh(this.geometry, this.material);
        // this.scene.add(this.cube);


        // SCALE ON RESIZE

        // Check "How can scene scale be preserved on resize?" section of Three.js FAQ
        // https://threejs.org/docs/index.html#manual/en/introduction/FAQ

        // code below is taken from Three.js fiddle
        // http://jsfiddle.net/Q4Jpu/

        // remember these initial values

        this.camera.position.x = 640/2;
        this.camera.position.y = -(480/2);

        // ANIMATE THE SCENE
        

        this.animate();
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.people && JSON.stringify(this.state.people) != JSON.stringify(this.props.people) ) {
            if(this.props.people.resolution){
                const { height, width } = this.props.people.resolution;
                this.camera = new THREE.OrthographicCamera( width / - 2, width / 2, height / 2, height / - 2, 1, 1000 );
                this.camera.position.z = 5;
                this.camera.position.x = width/2;
                this.camera.position.y = -(height/2);
            }
            this.scene.add( this.group );
            this.setState({people:this.props.people})
        }else if(!this.props.people){
            if(prevState.people) this.scene.clear();
        };
    }

    clearScene = () => {
        while(this.group.children.length > 0){ 
            if (this.group.children[0].type == "Group") {
                this.group.children[0].children.map( mesh => mesh.geometry.dispose())
            }
            else if (this.group.children[0].type == "Mesh") this.group.children[0].geometry.dispose()
            else if (this.group.children[0].geometry) this.group.children[0].geometry.dispose()
            this.group.remove(this.group.children[0]); 
        }
    }	

    getShape(pos,color) {
        let array = this.state.people.people;

        let shape = [];
        pos.map( p => {
            if (array[p] && array[p][0] != 0 && array[p][1] != 0) 
                shape.push(new THREE.Vector3( array[p][0], array[p][1]*-1));
        })

        let geometryPoints = new THREE.BufferGeometry().setFromPoints( shape );
        let shap = new THREE.Line( geometryPoints, new THREE.LineBasicMaterial( { color, linewidth: 5 } ) );
        geometryPoints.dispose();

        this.group.add(shap);
        // return shape;
    }

    renderSceneEl = () => {

        let array = this.state.people.people;
        
        
        if (array) {
            // braço direito
            this.getShape([1,2,3,4],'#996600');
            // braço esquerdo
            this.getShape([1,5,6,7],'#390');
            // cabeça
            this.getShape([17,15,0,16,18],'#3d00b7');
            // tronco
            this.getShape([0,1,8],'#900');
            // perna esquerda
            this.getShape([14,21],'#063399');
            this.getShape([8,12,13,14,19,20],'#063399');
            // perna direita
            this.getShape([8,9,10,11,22,23],'#049999');
            this.getShape([11,24],'#049999');
        }
    }

    animate = () => {
        requestAnimationFrame(this.animate);
        this.clearScene();
        this.renderSceneEl();
        this.renderer.render(this.scene, this.camera);
    };
    
    render() {
        
        return <div ref={ref => (this.mount = ref)} />;
    }
}
export default Camera;