import axios from '../../../../api'

export function create(id, data) {    
    return axios.put('/conta/acessos/' + id, data)
}

export function update(data) {
    return axios.post('/conta/editar/acessos/' + data._id , data)
}

export function get(id) {
    return axios.get('/conta/acessos/' + id)
}
