module.exports = [
    {
        icon: 'desktop',
        label: 'Dashboard',
        to: '/dashboard',
        roles: ['admin','franquia','franqueado', 'cliente'],
        // content: [
        //     {
        //         label: 'Alertas',
        //         to: '/dashboard',
        //         roles: ['admin','franquia','franqueado', 'cliente'],
        //     },
        //     {
        //         label: 'Atividades',
        //         to: '/dashboard',
        //         roles: ['admin','franquia','franqueado', 'cliente'],
        //     },
        // ],
    },
    {
        icon: 'building',
        label: 'Franquias',
        roles: ['admin'],
        to: '/dashboard/admins',
    },
    {
        icon: 'building-o',
        label: 'Franqueados',
        roles: ['admin','franquia'],
        to: '/dashboard/franqueados'
    },
    {
        icon: 'user',
        label: 'Clientes',
        roles: ['franquia','franqueado'],
        to: '/dashboard/clientes'
    },
    {
        icon: 'video-camera',
        label: 'Dispositivos',
        to: '/dashboard/dispositivos',
        roles: ['cliente'],
        isMaster: true,
        onlySupervisor: true
    },
    {
        icon: 'bell',
        label: 'Alertas',
        to: '/dashboard/alertas',
        roles: ['cliente'],
        isMaster: true,
        onlySupervisor: true
    },
    {
        icon: 'vcard',
        label: 'Pessoas',
        to: '/dashboard/pessoas',
        roles: ['cliente'],
        isMaster: true,
        onlySupervisor: true
    },
    {
        icon: 'users',
        label: 'Controle de acesso',
        to: '/dashboard/acessos',
        roles: ['franquia','franqueado', 'cliente'],
        isMaster: true
    },
    {
        icon: 'sliders',
        label: 'Grupo de atividades',
        to: '/dashboard/grupo_atividades',
        roles: ['admin'],
        isMaster: true
    },
    {
        icon: 'cubes',
        label: 'Objetos',
        to: '/dashboard/objeto',
        roles: ['admin'],
        isMaster: true
    },
];