import React, { Component } from "react";
import { store } from "./../../../../stores";

export function checkRoles(roles){
    let state = store.getState();
    return roles.includes(state.user.role)
}

export class HasRoles extends Component {

    render(){
        if (!checkRoles(this.props.roles))
            return(<></>);

        return(this.props.children)
    }

}
