export const type_people_status = [
    // {value:-1, label: 'Indefinido'},
    {value:0, label: 'Sentado'},
    {value:1, label: 'Em pé'},
    {value:2, label: 'Caido'},
    {value:3, label: 'Curvado'},
    {value:4, label: 'Agachado'},
]

export const type_openpose_data = [
    {value:0, label: 'Número de pessoas'},
    {value:1, label: 'Velocidade'},
    {value:2, label: 'Angulo'},
    {value:3, label: 'Reconhecimento Facial'},
    {value:4, label: 'Iluminação'},
]

export const type_conditions = [
    {value:0, label: 'Maior que'},
    {value:1, label: 'Menor que'},
    {value:2, label: 'Igual a'},
]