import axios from '../../../../api'

export function create(data) {    
    return axios.put('/admin/cadastrar', data)
}

export function update(data) {
    return axios.post('/admin/editar/' + data._id , data)
}

export function get(id) {
    return axios.get('/admin/listar/detalhe/' + id)
}
