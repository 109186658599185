import React, { Component } from "react";

import { Card, Alert } from 'reactstrap';
import FormGrupoAtividades from '../../forms/form-cadastro-grupo-atividades';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { create, update, get } from './api'

class CadastroGrupoAtividades extends Component {
    
    state ={
        errorMsg: null,
        successMsg: null,
        initialValue: null 
    }
    
    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Grupo de atividades',
        })
        
    }

    componentDidMount(){
        const { id } = this.props.match.params;
        let { user } = this.props.state;
        if (id){
            get(id).then(res => {
                let acesso = {
                    ...res.data,
                    password_confirm: res.data.password,
                    type: user.role 
                }
                this.setState({initialValue: acesso})
            })
        }else{
            this.setState({initialValue: {type:user.role}})
        }
    }

    _submit = (data) => {
        this.setState({errorMsg: null, successMsg: null})
        if(!data._id){
            create(data).then(res => {
                if(res.data && res.data.error){
                    console.log(res.data.error)
                    this.setState({errorMsg:res.data.error})
                }else{
                    this.setState({successMsg:"Cadastro realizado com sucesso. Redirecionando..." })
                    setTimeout(()=>this.props.history.goBack(),2000)
                }
            })
        }else{
            update(data).then(res => {
                if(res.data && res.data.error){
                    console.log(res.data.error)
                    this.setState({errorMsg:res.data.error})
                }else{
                    this.setState({successMsg:"Atualizado com sucesso. Redirecionando..." })
                    setTimeout(()=>this.props.history.goBack(),2000)
                }
            })
        }
    }    

    render(){
        const { errorMsg, successMsg, initialValue } = this.state
        return(
            <Card body>
                <FormGrupoAtividades onSubmit={(data) => this._submit(data)} initialValues={initialValue}/>
                {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
                {successMsg && <Alert color="success">{successMsg}</Alert>}
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CadastroGrupoAtividades);
