import React from "react";
import './index.css';

// Components
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

// Pages
import Dashboard from "./pages/dashboard"; 
import CadastroCliente from "./pages/cadastro-cliente";
import CadastroFranqueado from "./pages/cadastro-franqueado";
import CadastroAdmin from "./pages/cadastro-admin";
import CadastroAcessos from "./pages/cadastro-controle-acesso";
import CadastroGrupoAtividades from "./pages/cadastro-grupo-atividades";
import CadastroDispositivos from "./pages/cadastro-dispositivos";
import CadastroObjetos from "./pages/cadastro-objeto";
import CadastroPessoas from "./pages/cadastro-pessoas";
import CadastroAlertas from "./pages/cadastro-alertas";
import listaCliente from "./pages/lista-cliente";
import listaFranquado from "./pages/lista-franquado";
import listaAdmin from "./pages/lista-admin";
import listaAcessos from "./pages/lista-controle-acessos";
import listaGrupoAtividades from "./pages/lista-grupo-atividades";
import listaDispositivos from "./pages/lista-dispositivos";
import listaObjetos from "./pages/lista-objeto";
import listaPessoas from "./pages/lista-pessoas";
import listaAlertas from "./pages/lista-alertas";

function DashboardRouter (props) {
    let { path } = useRouteMatch();
    return(
        <Switch>
            <Route exact path={path} component={Dashboard}/>
            
            <Route exact path={path + "/clientes"} component={listaCliente}/>
            <Route exact path={path + "/cliente/cadastro/:id"} component={CadastroCliente}/>
            <Route exact path={path + "/cliente/cadastro"} component={CadastroCliente}/>

            <Route exact path={path + "/franqueados"} component={listaFranquado}/>
            <Route exact path={path + "/franqueado/cadastro/:id"} component={CadastroFranqueado}/>
            <Route exact path={path + "/franqueado/cadastro"} component={CadastroFranqueado}/>

            <Route exact path={path + "/admins"} component={listaAdmin}/>
            <Route exact path={path + "/admin/cadastro/:id"} component={CadastroAdmin}/>
            <Route exact path={path + "/admin/cadastro"} component={CadastroAdmin}/>

            <Route exact path={path + "/acessos"} component={listaAcessos}/>
            <Route exact path={path + "/acessos/cadastro/:id"} component={CadastroAcessos}/>
            <Route exact path={path + "/acessos/cadastro"} component={CadastroAcessos}/>

            <Route exact path={path + "/grupo_atividades"} component={listaGrupoAtividades}/>
            <Route exact path={path + "/grupo_atividades/cadastro/:id"} component={CadastroGrupoAtividades}/>
            <Route exact path={path + "/grupo_atividades/cadastro"} component={CadastroGrupoAtividades}/>

            <Route exact path={path + "/objeto"} component={listaObjetos}/>
            <Route exact path={path + "/objeto/cadastro/:id"} component={CadastroObjetos}/>
            <Route exact path={path + "/objeto/cadastro"} component={CadastroObjetos}/>

            <Route exact path={path + "/dispositivos"} component={listaDispositivos}/>
            <Route exact path={path + "/dispositivos/cadastro/:id"} component={CadastroDispositivos}/>
            <Route exact path={path + "/dispositivos/cadastro"} component={CadastroDispositivos}/>

            <Route exact path={path + "/pessoas"} component={listaPessoas}/>
            <Route exact path={path + "/pessoas/cadastro/:id"} component={CadastroPessoas}/>
            <Route exact path={path + "/pessoas/cadastro"} component={CadastroPessoas}/>

            <Route exact path={path + "/alertas"} component={listaAlertas}/>
            <Route exact path={path + "/alertas/cadastro/:id"} component={CadastroAlertas}/>
            <Route exact path={path + "/alertas/cadastro"} component={CadastroAlertas}/>

            <Redirect to={'/404'}/>
        </Switch>
    )
}

export default DashboardRouter;