import React, { Component } from "react";

import { Card, Alert } from 'reactstrap';
import FormCadastroCliente from '../../forms/form-cadastro-cliente';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";
import { create, update, get } from './api'

class CadastroClientes extends Component {
    
    state ={
        errorMsg: null,
        successMsg: null,
        initialValue: null 
    }

    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Cadastro de Cliente',
        })
        
    }
    componentDidMount(){
        const { id } = this.props.match.params
        if (id){
            get(id).then(res => {
                let cliente = {
                    ...res.data.cliente,          
                    login: res.data.login,      
                    password: res.data.password,
                    password_confirm: res.data.password 
                }
                this.setState({initialValue: cliente})
            })
        }
    }

    _submit = (data) => {      
        this.setState({errorMsg: null, successMsg: null})  
        let { user } = this.props.state;
        if(!data._id){
            create(user.id, data).then(res => {
                console.log(res.data)
                if(res.data && res.data.error){
                    console.log(res.data.error)
                    this.setState({errorMsg:res.data.error})
                }else{
                    this.setState({successMsg:"Cadastro realizado com sucesso. Redirecionando..." })
                    setTimeout(()=>this.props.history.goBack(),2000)
                }
            }).catch(err =>{
                console.log(err)
                this.setState({errorMsg:"Erro ao realizar cadastro" })
            })
        }else{
            update(data).then(res => {
                if(res.data && res.data.error){
                    console.log(res.data.error)
                    this.setState({errorMsg:res.data.error})
                }else{
                    this.setState({successMsg:"Atualizado com sucesso. Redirecionando..." })
                    setTimeout(()=>this.props.history.goBack(),2000)
                }
            }).catch( err => {
                console.log(err)
                this.setState({errorMsg:"Erro ao atualizar cadastro" })
            })
        }
    }

    render(){
        const { errorMsg, successMsg, initialValue } = this.state
        return(
            <Card body>
                <FormCadastroCliente onSubmit={(data) => this._submit(data)} initialValues={initialValue}/>
                {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
                {successMsg && <Alert color="success">{successMsg}</Alert>}
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CadastroClientes);
