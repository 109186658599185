import React, { Component } from "react";

import { Card, Table, Button, ButtonGroup, Spinner } from 'reactstrap';
import { FaPen, FaLock, FaLockOpen } from 'react-icons/fa';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { listAcessos } from '../../api'

class ListaControleAcesso extends Component {

    state={
        isLoading: true, 
        acessos: []
    }

    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Meus acessos',
            button:{
                label:'Novo acesso',
                action:()=>this.props.history.push('/dashboard/acessos/cadastro')
            }
        })
        let { user } = this.props.state
        listAcessos(user.id, user.role).then(res => {      
            console.log(res.data)      
            this.setState({
                isLoading: false,
                acessos: res.data
            })
        })
    }

    itemClick(id) {
        this.props.history.push('/dashboard/acessos/cadastro/'+id)
    }

    render(){
        let { acessos, isLoading }= this.state
        return(
            <Card body>
                {/* <FiltroLista onSubmit={(filtro)=>{console.log(filtro)}}/> */}
                <Table responsive>
                    <thead>
                        <th>Nome</th>
                        <th>Celular</th>
                        <th>Email</th>
                        <th></th>
                    </thead>
                    <tbody>
                    {isLoading && <Spinner color="primary" style={{ width: '5rem', height: '5rem', position:'absolute', left:'45%' }} />}
                    {acessos.map( f => (
                        <tr>
                            <td>{f.nome}</td>
                            <td>{f.celular}</td>
                            <td>{f.login}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    <Button size={'sm'} onClick={()=>this.itemClick(f._id)}><FaPen/></Button>
                                    {/* <Button size={'sm'} color={'danger'}><FaLock/><FaLockOpen/></Button> */}
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}  
                    </tbody>
                </Table>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaControleAcesso);