import axios from '../../../../api'

export function create( data ) {    
    return axios.put('/grupo_atividade/cadastrar', data)
}

export function update(data) {
    return axios.post('/grupo_atividade/editar/' + data._id , data)
}

export function get(id) {
    return axios.get('/grupo_atividade/get/' + id)
}
