import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form';

import page from './PageConfig';
import user from './User';

export default combineReducers({
    form: formReducer,
    page,
    user
})