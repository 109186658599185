import React, { Component, useReducer } from "react";
import "./index.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

import DashboardCliente from './cliente';
import DashboardFranqueado from './franqueado';
import DashboardAdmin from './admin';

class Dashboard extends Component {

    constructor(props){
        super(props)
    }

    render(){

        let { user } = this.props.state;
        if(user.role == 'cliente')
            return <DashboardCliente {...this.props}/>
        
        else if(user.role == 'franqueado')
            return <DashboardFranqueado {...this.props}/>

        else if(user.role == 'admin' || user.role == 'franquia')
            return <DashboardAdmin {...this.props}/>

        return(<></>)
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
