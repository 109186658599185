import React from "react"
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';

const renderField = (props,{input}) => (
        <input {...props} {...props.input} />
)

let ResetPasswordForm = props => {
    const { handleSubmit, pristine, submitting } = props
    return (
        <form onSubmit={handleSubmit}>
            <Field name="email" type="email" className="form-control" component={renderField} placeholder="Email" autofocus required/>
            <Button type="submit" disabled={pristine || submitting} block>Recuperar senha</Button>
        </form>
    )
}

ResetPasswordForm = reduxForm({
  form: 'ResetPasswordForm'
})(ResetPasswordForm)

export default ResetPasswordForm