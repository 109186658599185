import React, { Component } from "react";
import './index.css';

// Components
// import TopBar from "./components/TopBar";
import UserWrapper from "./components/UserWrapper";
import MainMenu from "./components/MainMenu";
import { MainContentWrapper,MainContentInner, PageContent} from "./components/Wrappers";
import { connect } from 'react-redux';
import {Container } from 'reactstrap';
import DashboardRouter from "./routes";

class DashboardLayout extends Component {
    render(){

        let { user } = this.props.state;

        return(
            <div className="page-full">
                {/* <TopBar /> */}
                <UserWrapper breadcrumbs={this.props.location.pathname}/>
                
                <MainContentWrapper>
                    <div className="container">
                        <MainContentInner>
                            <MainMenu history={this.props.history}/>
                            <PageContent>
                                <DashboardRouter/>
                            </PageContent>
                        </MainContentInner>
                    </div>
                </MainContentWrapper>
            </div>
        )
    }

}

export default connect(state=>({state}))(DashboardLayout);