import React, { Component } from "react";
import menu from './menu_itens';
import { Link,NavLink } from 'react-router-dom';
import './index.css';

import { FiX, FiLogOut } from 'react-icons/fi';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import MetisMenu from '@metismenu/react';
import 'metismenujs/dist/metismenujs.css';
import { Button, Alert } from "reactstrap";
import { contaInfo } from '../../api';

class MainMenu extends Component {

    checkRoles(menu,user,isMaster){
        let checkedMenu = [];
        for (let i = 0; i < menu.length; i++) {
            const m = menu[i];
            if (m.roles && !m.roles.includes(user.role)) 
                continue;
            if (m.content && m.content.length > 0)
                m.content = this.checkRoles(m.content,user,isMaster);
            if(m.isMaster && !isMaster){
                continue;
            }
            if ( user.profile.length === 0 && m.onlySupervisor ){
                continue;
            } 
            checkedMenu.push(m);
        }
        return checkedMenu;
    }

    _accessOriginalView(user) {
        const profile = user.profile;
        const lastUser = profile.pop()
        contaInfo(lastUser.id,lastUser.role).then(res => {

            if (!res.data) {
                alert('Erro ao obter informações do cliente');
                return;
            }

            let conta = res.data;
            const userProfileRole = lastUser.role == 'franquia' ? 'admin' : lastUser.role; 
            let newUser = {
                profile,
                id: conta[userProfileRole]._id,
                codId: conta[userProfileRole].codId,
                isMaster: conta.isMaster,
                nome: conta[userProfileRole].nome,
                email: conta[userProfileRole].email,
                role: conta.type
            }

            console.log(newUser);

            localStorage.setItem('auth',JSON.stringify(newUser))
            this.props.history.push('/dashboard')

        })
    }

    render(){

        let { menuVisible } = this.props.page;        
        let { user } = this.props;
        let logoutRole = user.role == 'franquia' ? 'admin': user.role;   
        let content = this.checkRoles(menu,user || null, user.isMaster || null);

        return(
            <>
                <div className={"menu-shader " + ( menuVisible ? 'open' : '')}  onClick={()=>this.props.toogleMenu()}></div>
                <div className={"menu-wrapper " + ( menuVisible ? 'menu-open' : '')} >
                    <div>
                        <div className="menu-toogle-close"><FiX onClick={()=>this.props.toogleMenu()}/></div>
                        <div className="menu-user-info">
                            <span><b>{user.profile[0]?.nome || user.nome}</b></span><br/>
                            <span>{user.profile[0]?.role || user.role}</span>
                        </div>
                        {
                            user.profile.length > 0 ? 
                            <Alert color="warning" className="different-profile-alert">
                                Perfil de: { user.nome } <br/>
                                <a href="#" onClick={()=>this._accessOriginalView(user)}>Voltar para {user.profile[user.profile.length-1].nome}</a>
                            </Alert> : null
                        }
                        <span className="menu-title">MENU</span>
                        <MetisMenu>
                            <MenuItems content={content}/> 
                        </MetisMenu>
                    </div>
                    <div className="logout-menu-button">
                        <Link to={'/login/'+ logoutRole} ><Button color="danger" size="sm" block><FiLogOut/> Sair</Button></Link>
                    </div>
                </div>
            </>
            
        )
    }
}

class MenuItems extends Component {
    render(){
        let { content } = this.props;
        return(
            <>
            {
                content.map((item,index) => {
                    return(
                        <>
                            <li>
                                {item.to ? <NavLink exact to={item.to}>{<i class={"fa fa-"+item.icon}></i>} {item.label}</NavLink> : <Link to="#" className="has-arrow">{<i class={"fa fa-"+item.icon}></i>}  {item.label}</Link>}
                                { item.content && <ul><MenuItems content={item.content}/></ul>}
                            </li>
                    </>
                    )
                })
            }
            </>
        )
    }
}

const mapStateToProps = (state) => (state);    
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);