import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import moment from 'moment';
import {store} from './stores'; 
import { bindActionCreators } from "redux";
import { Creators as UserActions } from "./stores/modules/User/actions";

// Components
import Dashboard from './views/Dashboard';
import { Login } from './views/Auth';

const actions = bindActionCreators({...UserActions}, store.dispatch);



export default function MainRoute() {
  return (
    <Router basename='/'>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/login/:type" component={Login}/>
          <Route exact path="/login/" component={Login}/>
          <PrivateRoute path="/dashboard" component={Dashboard}/>
          <Route path="*" component={()=><h1>404 - Page Not Found</h1>} status={404}/>
        </Switch>
    </Router>
  );
}

function isAuthenticated() {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if(
      !auth ||  
      !auth.id ||  
      moment(auth.timestamp).add('1 days').isAfter(moment()) 
    )
      return false;
    
    actions.setUser(auth);
    return true;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
);