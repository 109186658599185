import { createReducer } from "reduxsauce";
import { Types } from "./actions";

const initialState = {
    title: '',
    button:{},
    menuVisible: false,
    notification: {}
};

const setPageSettings = (state = initialState, action) => {
    return {
        ...state,
        title: action.data.title,
        button: action.data.button,
        menuVisible:false,
        notification: action.data.notification || {}
    };
};

const toogleMenu = (state = initialState, action) => {
    return {
        ...state,
        menuVisible: !state.menuVisible
    };
};

export default createReducer(initialState, {
    [Types.SET_PAGE_SETTINGS]: setPageSettings,
    [Types.TOOGLE_MENU]: toogleMenu,
});