import React, { useEffect } from 'react'
import { Field, reduxForm, FormSection,formValues } from 'redux-form';
import { Button,Row,Col } from 'reactstrap';
import { SimpleInput, HiddenInput } from '../components/FormInputs';

const validate = values => {
    const errors = {}
    if (!values.label) 
        errors.label = 'Obrigatório';
    if (!values.color) 
        errors.color = 'Obrigatório';
    return errors;
}

let FormGrupoAtividades = props => {

    useEffect(() => {
        if(props.initialValue)
            props.initialize(props.initialValue)
    }, [props]);

    const { handleSubmit, pristine, submitting, invalid } = props

    return (
        <form onSubmit={handleSubmit}>
            <Field name="_id" type="hidden" component={HiddenInput}/>
            <Row>
                <Col xs="12" md="6" lg="12"><Field name="label" type="text" placeholder="Ex: Descanço" component={SimpleInput} label="Nome" /></Col>                
                <Col xs="12" sm="6"><Field name="tipo" type="text" placeholder="Ex: Soneca" component={SimpleInput} label="Tipo" /></Col>
                <Col xs="12" sm="6"><Field name="color" type="text" placeholder="Ex: #128C7E" component={SimpleInput} label="Cor" /></Col>
            </Row>
            <div>
                <Button type="submit" disabled={invalid || pristine || submitting}>Salvar</Button>
            </div>

        </form>
    )
}

export default reduxForm({
    form: 'FormGrupoAtividades',
    validate
  })(FormGrupoAtividades)