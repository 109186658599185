import axios from '../../../../api'

export function create(franqueado_id, data) {    
    return axios.put('/cliente/cadastrar/' + franqueado_id, data)
}

export function update(data) {
    return axios.post('/cliente/editar/' + data._id , data)
}

export function get(id) {
    return axios.get('/cliente/get/' + id)
}
