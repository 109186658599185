import React, { Component } from "react";

import { Card, Table, Button, ButtonGroup, Spinner } from 'reactstrap';
import { FaPen, FaLock, FaLockOpen } from 'react-icons/fa';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { listGrupoAtividades } from '../../api'

class ListaControleAcesso extends Component {

    state={
        isLoading: true, 
        list: []
    }

    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Grupo Atividades',
            button:{
                label:'Novo grupo de atividades',
                action:()=>this.props.history.push('/dashboard/grupo_atividades/cadastro')
            }
        })
        let { user } = this.props.state
        listGrupoAtividades(user.id, user.role).then(res => {         
            this.setState({
                isLoading: false,
                list: res.data
            })
        })
    }

    itemClick(id) {
        this.props.history.push('/dashboard/grupo_atividades/cadastro/'+id)
    }

    render(){
        let { list, isLoading }= this.state
        return(
            <Card body>
                {/* <FiltroLista onSubmit={(filtro)=>{console.log(filtro)}}/> */}
                <Table responsive>
                    <thead>
                        <th>Nome</th>
                        <th>Tipo</th>
                        <th>Cor</th>
                        <th></th>
                    </thead>
                    <tbody>
                    {isLoading && <Spinner color="primary" style={{ width: '5rem', height: '5rem', position:'absolute', left:'45%' }} />}
                    {list.map( l => (
                        <tr>
                            <td>{l.label}</td>
                            <td>{l.tipo}</td>
                            <td>{l.color}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    <Button size={'sm'} onClick={()=>this.itemClick(l._id)}><FaPen/></Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}  
                    </tbody>
                </Table>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaControleAcesso);