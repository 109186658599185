import React, { Component } from "react";

import { Card, Table, Button, ButtonGroup, Spinner, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaPen, FaLock, FaLockOpen, FaTrash, FaCheck, FaCamera } from 'react-icons/fa';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { listDispositivos, dispositivoToggle, uploadImageDispositivos } from '../../api'
import WsClient from '../../../../helpers/ws-client';
class ListaControleAcesso extends Component {

    state={
        isLoading: true, 
        isLoadingImage:false,
        imagemAtualizada:false,
        list: [],
        selectedItem: null,
        modal: false
    }

    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Dispositivos cadastrados',
            button:{
                label:'Novo dispositivo',
                action:()=>this.props.history.push('/dashboard/dispositivos/cadastro')
            }
        })

        this._listDispositivos();
    }

    componentDidMount(){
        let { user } = this.props.state;
        const wssEndpoint = 'wss://anglis.com.br/myws';
        // const wssEndpoint = 'ws://localhost:8888';
        this.onWsOpened = this.onWsOpened.bind(this);
        this.onWsMessageReceived = this.onWsMessageReceived.bind(this);
        this.onWsClosed = this.onWsClosed.bind(this);
        this.onWsError = this.onWsError.bind(this);        
        
        this.wsClient = new WsClient(
            wssEndpoint,
            this.onWsOpened,
            this.onWsMessageReceived,
            this.onWsClosed,
            this.onWsError,
            user.id
        );

        this.wsClient.initialize();

    }

    // ws region
    onWsOpened(){ 
        console.log('websocket opened'); 
    };

    onWsMessageReceived(eventJson){ 
        // console.log('websocket onWsMessageReceived', eventJson); 
        const event = JSON.parse(eventJson.data);
        const eventType = event.type;            
        const data = event.data;            
        switch (eventType) {
            case 'NOTIFICATION': {
                // this._getUserAlertas( this.props.state.user.id )
            } break;
            
            case 'PEOPLE_STATUS': {
                // this.setState({people:data})
            } break;

            default: {}
        }
    };

    onWsClosed(){ 
        console.log('websocket closed'); 
    };

    onWsError(error){ 
        console.log('websocket onWsError', error); 
    };
    // ws region

    openModal(selectedItem){
        this.setState({selectedItem})
        this.toggleModal();
    }

    requestAmbienteImage(codId, mac){
        const { user } = this.props.state;
        this.setState({isLoadingImage: true, modal: false})
        setTimeout(() => {
            this.setState({isLoadingImage:false})
            this.setState({imagemAtualizada:true})
            setTimeout(() => {
                this.setState({imagemAtualizada: false})
            }, 3000)

        }, 3000)
        this.wsClient.sendWebsocketMessage({
            type: 'REQUEST_AMBIENT_IMAGE',
            data: {
                clienteCodID: mac,
                device: codId, 
            }
        });
    }

    _listDispositivos() {
        let { user } = this.props.state
        listDispositivos(user.id).then(res => {      
            this.setState({
                isLoading: false,
                list: res.data
            })
        })
    }

    _itemEdit(id) {
        this.props.history.push('/dashboard/dispositivos/cadastro/'+id)
    }

    _itemToogleStatus(active,id) {
        let status = active ? 'desativar' : 'ativar';  
        dispositivoToggle(status,id).then(res => {
            this._listDispositivos();
        })
    }
    
    onUpload = (e) => {
        let file = e.target.files[0]
        const data = new FormData()
        data.append('file', file)
        uploadImageDispositivos(data, this.state.device_id).then(res => {
           console.log(res.data)
        })
    }

    toggleModal = () => {
        this.setState({
          modal: !this.state.modal
        });
    };

    renderModal(){
        const { selectedItem } = this.state;
        if(!selectedItem) return;
        return(
            <Modal
                toggle={this.toggleModal}
                isOpen={this.state.modal}
            >
                <ModalHeader toggle={this.toggleModal}>
                    {selectedItem.label} - {selectedItem.local}
                </ModalHeader>
                <ModalBody>
                    Deseja capturar uma nova foto do Ambiente ?
                    <br/><br/>
                    *Ambiente deve estar sem pessoas para iniciar a captura.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {this.requestAmbienteImage(selectedItem.codId, selectedItem.mac )}}
                    >
                        Capturar
                    </Button>
                    {' '}
                    <Button 
                        color='danger'
                        onClick={this.toggleModal}
                    >
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render(){
        let { list, isLoading, isLoadingImage, imagemAtualizada }= this.state
        return(
            <Card body>
                {
                    imagemAtualizada && 
                        <Alert color={'success'} className="alertas"   >
                            Imagem Atualizada
                        </Alert>
                }
                {this.renderModal()}
                <Table responsive>
                    <thead>
                        <th>MAC</th>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Local</th>
                        <th></th>
                    </thead>
                    <tbody>
                    {isLoading && <Spinner color="primary" style={{ width: '5rem', height: '5rem', position:'absolute', left:'45%' }} />}
                    {console.log(list)}
                    {list.map( l => (
                        <tr>
                            <td>{l.mac}</td>
                            <td>{l.codId}</td>
                            <td>{l.label}</td>
                            <td>{l.local}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    <Button  
                                        disabled={isLoadingImage} 
                                        color={'info'} 
                                        size={'sm'} 
                                        onClick={() => { 
                                            this.openModal(l) 
                                        }}
                                    > 
                                    {
                                        isLoadingImage ? 
                                            <Spinner  size={'sm'}/>
                                        :
                                            <FaCamera/>
                                    }
                                    </Button>  
                                    {/* <input type="file" style={{ display: 'none' }} onChange={this.onUpload} ref={e => { this.fileInput = e }} />                                   */}
                                    <Button size={'sm'} onClick={()=>this._itemEdit(l._id)}><FaPen/></Button>
                                    {
                                        l.active ? 
                                        <Button size={'sm'} color={'success'} onClick={()=>this._itemToogleStatus(l.active,l._id)}><FaCheck/></Button> :
                                        <Button size={'sm'} color={'danger'} onClick={()=>this._itemToogleStatus(l.active,l._id)}><FaTrash/></Button> 
                                    }
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}  
                    </tbody>
                </Table>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaControleAcesso);