import api from '../../api';
import axios from 'axios';

//get address by cep
export function getAddressByCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`)
}

//get conta info
export function contaInfo(id,type) {
    return api.post('/conta/obter/'+id,{type})
}


//lista-admin
export function listAdmins() {
    return api.get('/admin/listar/')
}

//lista-franqueado
export function listFranqueados(id) {    
    return api.get('/franqueado/listar/' + id)
}

//lista-cliente
export function listClientes(id) {    
    return api.get('/cliente/getAll/' + id)
}

//lista-acessos
export function listAcessos(id, type) {    
    return api.post('/conta/acessos/' + id, {type})
}

//lista-grupo_atividade
export function listGrupoAtividades() {    
    return api.get('/grupo_atividade/getAll' )
}

//lista-objetos
export function listObjetos() {    
    return api.get('/objeto/getAll' )
}

//list-dispositivos
export function listDispositivos(codId) {    
    return api.get('/device/getAll/' + codId )
}

// uploadImageDispositivos
export function uploadImageDispositivos(data, id) {
    return api.post('/device/upload/' + id, data)
}

//list-alertas
export function listAlertas(id) {    
    return api.get('/alerta_configs/getAll/' + id )
}

//get Devices Admin
export function listDispositivosAdmin() {    
    return api.get('/device/getAllAdmin' )
}

//get Devices Franqueado
export function listDispositivosFranqueado(franqueadoId) {    
    return api.get('/device/getAllFranqueado/'+franqueadoId )
}

//list-pessoas
export function listPessoas(codId) {    
    return api.get('/people/getAll/' + codId )
}

//toggle-pessoas
export function pessoasToggle(status,id) {    
    return api.post(`/people/${status}/${id}`)
}

//toggle-dispositivo
export function dispositivoToggle(status,id) {    
    return api.post(`/device/${status}/${id}`)
}

//toggle-dispositivo
export function alertasToggle(status,id) {    
    return api.post(`/alerta_configs/${status}/${id}`)
}

//Active Functions
export function handleActiveAdmin(id, status){
    if(status){
        return api.delete('/admin/desativar/' + id);
    }else{
        return api.post('/admin/ativar/' + id)
    }
}

export function handleActiveFranqueado(id, status){
    if(status){
        return api.delete('/franqueado/desativar/' + id);
    }else{
        return api.post('/franqueado/ativar/' + id)
    }
}

export function handleActiveCliente(id, status){
    if(status){
        return api.delete('/cliente/desativar/' + id);
    }else{
        return api.post('/cliente/ativar/' + id)
    }
}

export function getAlertasCliente(clienteId,param) {
    return api.get('/alerta/get/'+clienteId , {params: param || {}})
}

export function dismissAlerta(id) {
    return api.post('/alerta/desativar/'+id)
}

//get openpose_dara from client
export function getDeviceOpenPoseData(clienteId) {    
    return api.get('/openpose_data/get/cliente/'+clienteId )
}

export function getOpenPoseDataCharts(franqueado = null, cliente = null) {    
    return api.post('/openpose_data/get/charts', {franqueado, cliente} )
}