import React from 'react';
import './index.css';
import AuthLoginForm from './forms/form-login';
import ResetPasswordForm from './forms/form-reset-password';
import { Redirect,Link } from "react-router-dom";
import { Alert } from 'reactstrap'
import { login, forgotPassword } from './api'
import moment from 'moment';

export class Login extends React.Component {

    constructor(props){
        super(props);
        localStorage.removeItem('auth')
    }

    state={
        errorLogin: null,
        errorResetPassword: null,
        successResetPassword: null,
        page: 1
    }

    _login = ({email,password},type) => {
        this.setState({errorLogin: null})
        login(email, password, type).then(res => {

            console.log(res);

            if (res.data && res.data.error) {
                if (res.data.error === 'conta_invalid') {
                    this.setState({errorLogin: 'Login ou senha incorreto'})
                } else if (res.data.error === 'conta_inactive') {
                    this.setState({errorLogin: 'Sua conta está inativa'})
                } else {
                    this.setState({errorLogin: 'Login ou senha incorreto'})
                }
            } else if (res.data && res.data.auth) {
                let user = res.data;

                localStorage.setItem('auth',JSON.stringify({
                    id: user.id,
                    codId: user.codId,
                    isMaster: user.isMaster,
                    nome: user.nome,
                    email: user.email,
                    role: user.perfil,
                    profile: [],
                    timestamp: moment().format()
                }))

                this.props.history.push('/dashboard')
            } else {
                this.setState({errorLogin: 'Login ou senha incorreto'})     
            }
        });        
    }

    resetPass = ({email},type) => {
        this.setState({errorResetPassword: null, successResetPassword: null})
        forgotPassword(email, type).then(res =>{
            if (res.data && res.data.error) {
                this.setState({ errorResetPassword: 'E-mail não encontrado.' })
            } else {
                this.setState({ successResetPassword: 'Verifique sua caixa de entrada no e-mail com a nova senha.' })
            }
        })
    }

    render(){

        const { errorLogin, errorResetPassword, successResetPassword, page } = this.state 
        let { type } = this.props.match.params;
        if (type && !["franqueado","admin"].includes(type) ) return( <Redirect to="/login"/>)

        return(
            <div className="d-flex h-100">
                {page === 1 ?
                <div className="form-signin text-center">
                    <h3>Entrar</h3>
                    <span>{type && type.toUpperCase()}</span>
                    <AuthLoginForm onSubmit={(data)=>this._login(data,type || 'cliente')}/>
                    {errorLogin && <Alert color="danger">{errorLogin}</Alert>}
                    <Link onClick={()=> this.setState({page:2})}>Esqueci minha senha</Link>
                </div>
                :
                <div className="form-signin text-center">
                    <h3>Esqueci minha senha</h3>
                    <span>Informe seu e-mail e enviaremos instruções para você criar sua senha.</span>
                    <ResetPasswordForm onSubmit={(data)=>this.resetPass(data,type || 'cliente')}/>
                    {errorResetPassword && <Alert color="danger">{errorResetPassword}</Alert>}
                    {successResetPassword && <Alert color="success">{successResetPassword}</Alert>}
                    <Link onClick={()=> this.setState({page:1})}>Voltar para o login</Link>
                </div>
                }
            </div>
        )
    }
}