import React, { Component } from "react";
import { UserHeaderWrapper } from "../Wrappers";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import {  FiLogOut,FiMenu, FiBell } from 'react-icons/fi';
import './index.css';

import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

class UserWrapper extends Component {
    state = {notification:false}
    render(){
        let { page, user} = this.props;
        let logoutRole = user.role == 'franquia' ? 'admin': user.role;
        return(
            <UserHeaderWrapper>
                <div className="container innerContainer">
                    <div className="sidebar-user-info">
                        <div className="side-info">
                            { user.profile[0]?.nome || user.nome }
                            <br/>
                            { user.profile[0]?.role || user.role }
                        </div>
                        <div className="side-actions">
                            <Link to={'/login/'+ logoutRole} ><Button size={'sm'} color={'danger'}><FiLogOut/></Button></Link>
                        </div>
                    </div>
                    <div className="menu-toogle-open"><FiMenu onClick={()=>this.props.toogleMenu()}/></div>
                    <div className="header-content-info">
                        <h3 style={{color:'white'}}>{page.title}</h3>
                        { page.button?.label && <Button color="primary" onClick={()=>page.button?.action()}>{page.button?.label}</Button>}
                        
                        {
                            page.title == 'Dashboard' &&
                            <>
                                { this.state.notification && <div className="notification-closer" onClick={()=>this.setState({notification:false})}></div>}
                                <div className="notification-area" onClick={()=>this.setState({notification:true})}>
                                    <FiBell style={{color:'white',fontSize:'28px'}}/>
                                    {page.notification.count > 0 && <span className="notification-counter">{page.notification.count}</span>}
                                    <div className="notification-content" style={{display: this.state.notification ? 'block':'none'}}>
                                        { page.notification.content || <span style={{color:'#b1b1b1',display: 'block',textAlign: 'center'}}>Sem notificações</span>  }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </UserHeaderWrapper>
        )
    }
}

const mapStateToProps = (state) => (state);  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserWrapper);