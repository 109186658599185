import React, { Component } from "react";
import { Card, Button, CardTitle, CardText, Row, Col, Container } from 'reactstrap';
import Chart from 'chart.js';

import { listClientes, listDispositivosFranqueado, getOpenPoseDataCharts } from '../../api';

class DashboardFranqueado extends Component {

    constructor(props){
        super(props)

        this.chart1 = React.createRef();
        this.chart2 = React.createRef();
        this.chart3 = React.createRef();

        this.state = {
            countCliente: 0,
            countDevices: 0
        }
        
        this.props.setPageSettings({
            title:'Dashboard'
        })

        let { user } = this.props.state; 

        listClientes(user.id).then(({data}) => {
            if (data)
                this.setState({countCliente: data.length})
        })

        listDispositivosFranqueado(user.id).then(({data}) => {
            if (data)
                this.setState({countDevices: data.length})
        })

        getOpenPoseDataCharts(user.id).then(res=>{
            console.log(res.data)
            this.initChart(this.chart1,'Tempo em cada cômodo',res.data.chart1.labels, res.data.chart1.data)
            this.initChart(this.chart2,'Tempo em pé X Tempo sentado X Tempo deitado',res.data.chart2.labels, res.data.chart2.data)
            this.initChart(this.chart3,'Tempo parado X Tempo se movimentando',res.data.chart3.labels, res.data.chart3.data)
        })

    }

    componentDidMount(){
        // let labels = ['Sala', 'Cozinha', 'Quarto', 'Bainheiro', 'Quintal']
        // let data = [this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100)]        
        // this.initChart(this.chart1,'Tempo em cada cômodo',labels, data)
        // labels = ['Em pé', 'Sentado', 'Deitado']
        // data = [this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100)]        
        // this.initChart(this.chart2,'Tempo em pé X Tempo sentado X Tempo deitado',labels, data)
        // labels = ['Parado', 'Movimentando']
        // data = [this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100)]        
        // this.initChart(this.chart3,'Tempo parado X Tempo se movimentando',labels, data)
    }

    initChart(chart, title, labels, data){
        console.log(data)
        let backgroundColor = []
        let borderColor = []
        data.map((d)=>{
            backgroundColor.push(`rgba(${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, 0.2)`);
            borderColor.push(`rgba(${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, 0.2)`);
        });
        if(chart)
            new Chart(chart, {
                type: 'doughnut',
                data: {
                    labels,
                    datasets: [{
                        label: '# of Votes',
                        data: data,
                        backgroundColor,
                        borderColor,
                        borderWidth: 1
                    }],
                },
                options: {
                    title: {
                        display: true,
                        text: title
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var allData = data.datasets[tooltipItem.datasetIndex].data;
                                var tooltipLabel = data.labels[tooltipItem.index];
                                var tooltipData = allData[tooltipItem.index];
                                var total = 0;
                                for (var i in allData) {
                                    total += allData[i];
                                }
                                var tooltipPercentage = Math.round((tooltipData / total) * 100);
                                return tooltipLabel + ': (' + tooltipPercentage + '%)';
                            }
                        }
                    }
                }
            })
    }

    getRandomArbitrary(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    render(){
        let {countCliente, countDevices} = this.state
        return(
            <>
                <Row>
                    <Col className="indicador" md={6}>
                        <Card>
                            <h5>Clientes Cadastrados</h5>
                            <h2>{countCliente}</h2>
                        </Card>
                    </Col>
                    <Col className="indicador" md={6}>
                        <Card>
                            <h5>Dispositivos Cadastrados</h5>
                            <h2>{countDevices}</h2>
                        </Card>
                    </Col>
                    <Col className="indicador" md={12}>
                        <Card>
                            <canvas id="myChart" ref={(c) => this.chart1 = c} ></canvas>
                        </Card>
                    </Col>
                    <Col className="indicador" md={6}>
                        <Card>
                            <canvas id="myChart2" ref={(c) => this.chart2 = c} ></canvas>
                        </Card>
                    </Col>
                    <Col className="indicador" md={6}>
                        <Card>
                            <canvas id="myChart3" ref={(c) => this.chart3 = c} ></canvas>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default DashboardFranqueado;