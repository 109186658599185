import React, { Component } from 'react';
import { reduxForm,FormSection,Field } from 'redux-form';
import { SimpleInput, FieldEstados } from '../components/FormInputs';
import { getAddressByCep } from '../api';
import { Row,Col } from 'reactstrap';
import { createTextMask } from 'redux-form-input-masks';


function FormEndereco (props) {

    let cepMask = createTextMask({
        pattern: '99999-999',
        guide: false,
        allowEmpty: true,
        onChange: cep => {
            if (cep != "") {
                var validacep = /^[0-9]{8}$/;    
                if(validacep.test(cep)) {
                    getAddressByCep(cep)
                        .then(res => {

                            if (res.data) {
                                if (res.data.erro){
                                    alert('CEP inválido');
                                    return;
                                }

                                props.change('endereco.nomeEndereco',res.data.logradouro);
                                props.change('endereco.complemento',res.data.complemento);
                                props.change('endereco.bairro',res.data.bairro);
                                props.change('endereco.cidade',res.data.localidade);
                                props.change('endereco.estado',res.data.uf);

                            }
                        })
                        .catch(err => console.log(err))
                }
            }
        }
    });
        
    return(
        <FormSection name="endereco">
            <Row>
                <Col xs="12" sm="6" lg="3"><Field name="cep" type="text" component={SimpleInput} label="CEP" {...cepMask} /></Col>
                <Col sm="12" lg="9"><Field name="nomeEndereco" type="text" component={SimpleInput} label="Endereço" /></Col>
                <Col xs="12" sm="6"><Field name="numero" type="text" component={SimpleInput} label="Número" /></Col>
                <Col xs="12" sm="6"><Field name="complemento" type="text" component={SimpleInput} label="Complemento" /></Col>
                <Col xs="12" md="12" lg="5"><Field name="bairro" type="text" component={SimpleInput} label="Bairro" /></Col>
                <Col xs="12" sm="6" lg="4"><Field name="cidade" type="text" component={SimpleInput} label="Cidade" /></Col>
                <Col xs="12" sm="6" lg="3"><Field name="estado" component={FieldEstados} label="Estado"/></Col>
            </Row>
        </FormSection>
    )
}

export default reduxForm({
    form: 'FormEndereco', // a unique identifier for this form
    asyncBlurFields: [] 
})(FormEndereco)