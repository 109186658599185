import { createNumberMask, createTextMask } from 'redux-form-input-masks';

export const cpfMask = createTextMask({
    pattern: '999.999.999-99',
    guide: false,
    allowEmpty: true,
});

export const cnpjMask = createTextMask({
    pattern: '99.999.999/9999-99',
    guide: false,
    allowEmpty: true,
});

export const phoneMask = createTextMask({
    pattern: '(99) 99999-9999',
    guide: false,
    allowEmpty: true,
});