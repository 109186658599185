import React, { Component } from "react";
import { Card, Button, CardTitle, CardText, Row, Col, Container,Alert } from 'reactstrap';
import Chart from 'chart.js';
import { FiTv, FiUser } from 'react-icons/fi';
import {listDispositivos, listPessoas, getDeviceOpenPoseData, getOpenPoseDataCharts, getAlertasCliente, dismissAlerta} from '../../api'
import { urlStorage } from '../../../../api'
import WsClient from '../../../../helpers/ws-client';
import { type_people_status } from '../../../../helpers/types';
import moment from 'moment';
import Camera from './camera'

const statusAlertColor = {
    1:'danger',
    2:'warning',
    3:'success',
}
class DashboardCliente extends Component {

    constructor(props){
        super(props)

        this.chart1 = React.createRef();
        this.chart2 = React.createRef();
        this.chart3 = React.createRef();
  
        this.state = {
            countDevices: 0, 
            pessoas: [],
            devicesCount: 0,
            devices: [],
            alertas: [],
            people:{},
            timeoutPeople: null
        }

        this.props.setPageSettings({
            title:'Dashboard'
        })

        let { user } = this.props.state; 

        listPessoas(user.id).then(res => {      
            this.setState({
                isLoading: false,
                pessoas: res.data
            })
        })

        listDispositivos(user.id).then(res => {        
            this.setState({
                isLoading: false,
                devicesCount: res.data.length
            })
        })

        this._getUserAlertas(user.id);

        getDeviceOpenPoseData(user.id).then(res => {
            this.setState({devices:res.data})
        })
        
        getOpenPoseDataCharts(null, user.id).then(res=>{
            this.initChart(this.chart1,'Tempo em cada cômodo',res.data.chart1.labels, res.data.chart1.data)
            this.initChart(this.chart2,'Tempo em pé X Tempo sentado X Tempo deitado',res.data.chart2.labels, res.data.chart2.data)
            this.initChart(this.chart3,'Tempo parado X Tempo se movimentando',res.data.chart3.labels, res.data.chart3.data)
        })

        const wssEndpoint = 'wss://anglis.com.br/myws';
        // const wssEndpoint = 'ws://localhost:8888';
        this.onWsOpened = this.onWsOpened.bind(this);
        this.onWsMessageReceived = this.onWsMessageReceived.bind(this);
        this.onWsClosed = this.onWsClosed.bind(this);
        this.onWsError = this.onWsError.bind(this);        
        
        this.wsClient = new WsClient(
            wssEndpoint,
            this.onWsOpened,
            this.onWsMessageReceived,
            this.onWsClosed,
            this.onWsError,
            user.id
        );

        this.wsClient.initialize();

    }

    _getUserAlertas = (id) => {
        getAlertasCliente(id,{visto:false}).then(res => {
            console.log(res.data);
            this.props.setPageSettings({
                ...this.props.state.page,
                notification: {
                    count:res.data.length,
                    content: res.data.map( alerta => {
                        return(
                            <Alert color={statusAlertColor[alerta.type]} className="alertas" toggle={() => dismissAlerta(alerta._id).then(()=>this._getUserAlertas(id))} >
                                {alerta.label}
                                <small className="timestamp">{moment(alerta.createdAt).format('HH:mm [em] DD [de] MMM [de] YYYY')}</small>
                            </Alert>
                        )
                    })
                }
            })
            this.setState({alertas:res.data});
        })
    }

    // ws region
    onWsOpened(){ 
        console.log('websocket opened'); 
    };

    onWsMessageReceived(eventJson){ 
        const event = JSON.parse(eventJson.data);
        const eventType = event.type;            
        const data = event.data;            
        switch (eventType) {
            case 'NOTIFICATION': {
                //TODO: stop calling api
                this._getUserAlertas( this.props.state.user.id )
            } break;
            
            case 'PEOPLE_STATUS': {
                clearTimeout(this.state.timeoutPeople)
                const timeoutPeople  = setTimeout(() => {
                    this.setState({people: {}})
                }, 6000);
                this.setState({people:data, timeoutPeople})
            } break;

            default: {}
        }
    };

    onWsClosed(){ 
        console.log('websocket closed'); 
    };

    onWsError(error){ 
        console.log('websocket onWsError', error); 
    };
    // ws region

    componentDidMount(){
        // let labels = ['Sala', 'Cozinha', 'Quarto', 'Bainheiro', 'Quintal']
        // let data = [this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100)]        
        // this.initChart(this.chart1,'Tempo em cada cômodo',labels, data)
        // labels = ['Em pé', 'Sentado', 'Deitado']
        // data = [this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100)]        
        // this.initChart(this.chart2,'Tempo em pé X Tempo sentado X Tempo deitado',labels, data)
        // labels = ['Parado', 'Movimentando']
        // data = [this.getRandomArbitrary(0,100), this.getRandomArbitrary(0,100)]        
        // this.initChart(this.chart3,'Tempo parado X Tempo se movimentando',labels, data)
    }

    getDate(date){
        date = new Date(date)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return  strTime + " " + date.getDate() + "/" + (date.getMonth()+1)  + "/" + date.getFullYear();  
    }

    getUserComodo(data){
        let label = ""
        data.map(d => {
            if(d.user) label = d.local 
        })
        return label
    }

    initChart(chart, title, labels, data){
        let backgroundColor = []
        let borderColor = []
        data.map((d)=>{
            backgroundColor.push(`rgba(${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, 0.2)`);
            borderColor.push(`rgba(${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, ${this.getRandomArbitrary(0,255)}, 0.2)`);
        });
        if(chart)
            new Chart(chart, {
                type: 'doughnut',
                data: {
                    labels,
                    datasets: [{
                        label: '# of Votes',
                        data: data,
                        backgroundColor,
                        borderColor,
                        borderWidth: 1
                    }],
                },
                options: {
                    title: {
                        display: true,
                        text: title
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var allData = data.datasets[tooltipItem.datasetIndex].data;
                                var tooltipLabel = data.labels[tooltipItem.index];
                                var tooltipData = allData[tooltipItem.index];
                                var total = 0;
                                for (var i in allData) {
                                    total += allData[i];
                                }
                                var tooltipPercentage = Math.round((tooltipData / total) * 100);
                                return tooltipLabel + ': (' + tooltipPercentage + '%)';
                            }
                        }
                    }
                }
        })
    }

    getRandomArbitrary(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    getPeopleStatusName(i){
        let people_status = type_people_status.find( (o) => { return o.value == i })
        if(people_status) return people_status.label
        else return ''
    }

    renderMqttInfo(info){        
        if(info)
            return(
                <div className="mqtt-info-box">
                    <span>Pessoas: {info.num_people}</span>
                    <span>Pose: { this.getPeopleStatusName(info.people_status) }</span>
                    <span>Velo: {info.vel}</span>
                    <span>Angulo: {info.angulo_tronco}</span>
                    <span>Rec: {info.people_face_rec}</span>
                </div>
            )
        return null
    }

    renderImage(img){
        return(
            <img src={img} style={{ width:80, height:80, objectFit:'contain' }}  ></img>
        )
    }

    renderComodo(data){

        let objs = new Array(6).fill(null);
        data.objetos.map( obj => {
            objs[obj.position] = obj;
        })

        return (
            <div className="block-comodo" id="block-comodo" style={ data.user ? {borderColor: '#519000'} : null }>
                <span 
                    style={ data.user ? {backgroundColor: '#519000'} : null } 
                    className="tittle"
                >   
                    {data?.local} 
                    {this.state.people && data.user ? this.state.people.people_face_rec : data?.additionalLabel}
                </span>
                {this.renderMqttInfo(this.state.people.device == data.codId ? this.state.people : null)}
                <Camera 
                    people={this.state.people.device == data.codId ? this.state.people : null  } 
                    background={data.imagem?.length > 10000 ? data.imagem : urlStorage+data.imagem} 
                />
                {/* <div className="comodo-objects"> */}
                    {/* {objs.map( obj => <div className="comodo-item">{obj?.objeto?.active ? this.renderImage(urlStorage + obj?.objeto?.icon) : <div style={{width:'80px'}}></div>}</div> )} */}
                    {/* {data?.user ? <FiUser className="comodo-user"/>:null} */}
                {/* </div> */}
            </div>
        )
    }

    render(){
        let {devicesCount, pessoas, devices} = this.state
        let comodos = ['Sala', 'Cozinha', 'Banheiro', 'Quarto', 'Quintal', 'Sala de jantar']
        return(
            <>
                <Row style={{marginBottom:'15px'}}>
                    <Col>
                        <Card style={{padding:'15px',flexDirection:'row',flexWrap: 'wrap'}}>
                            {
                                devices.map( data => this.renderComodo(data) )
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {/* {pessoas.map( p => (
                        <Col className="indicador" md={6}>
                            <Card>
                                <h5>{p.nome} está no(a)</h5>
                                <h2>{this.getUserComodo(devices)}</h2>
                            </Card>
                        </Col>
                    ))}
                    <Col className="indicador" md={6}>
                        <Card>
                            <h5>Dispositivos Cadastrados</h5>
                            <h2>{devicesCount}</h2>
                        </Card>
                    </Col> */}
                    <Col className="indicador" md={12}>
                        <Card>
                            <canvas id="myChart" ref={(c) => this.chart1 = c} ></canvas>
                        </Card>
                    </Col>
                    <Col className="indicador" md={6}>
                        <Card>
                            <canvas id="myChart2" ref={(c) => this.chart2 = c} ></canvas>
                        </Card>
                    </Col>
                    <Col className="indicador" md={6}>
                        <Card>
                            <canvas id="myChart3" ref={(c) => this.chart3 = c} ></canvas>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default DashboardCliente;
