import React, { useEffect } from 'react'
import { Field, FieldArray, reduxForm, FormSection,formValues } from 'redux-form';
import { Button,Row,Col } from 'reactstrap';
import { SimpleInput, HiddenInput, SimpleSelect } from '../components/FormInputs';
import { listObjetos, listDispositivos, listPessoas } from '../api'
import { type_people_status, type_openpose_data, type_conditions } from '../../../helpers/types'
import * as masks from './masks';

const validate = values => {
    const errors = {}
    return errors;
}

let objetos = [];
function getObjetos(){
    objetos = []
    listObjetos().then(({data})=>{
        data.map(obj => {if(obj.active) objetos.push({value:obj._id,label:obj.label})})
    })
}


let types = [
    {value:1, label: 'Vermelho'},
    {value:2, label: 'Amarelo'},
    {value:3, label: 'Verde'},
]

let typesEstado = type_people_status

const renderCondicoes = ({ fields }) => {
    return (
        <>
            <Row>
                <Col>
                    <Button color="success" style={{marginBottom:15}} onClick={() => fields.push({})}>Adicionar Condição</Button>
                </Col>
            </Row>
            {fields.map((cond, index) =>
                <Row key={index} style={{background: '#00000014',padding: 6,marginBottom: 15}}>
                    <Col>
                        <Field name={`${cond}.openpose_type`} options={type_openpose_data} component={SimpleSelect} label="Tipo de dado" />
                    </Col>
                    <Col>
                        <Field name={`${cond}.condicao_type`} options={type_conditions} component={SimpleSelect} label="Tipo de Condição" />
                    </Col>
                    <Col>
                        <Field name={`${cond}.value`} type="text" component={SimpleInput} label="Valor"/>
                    </Col>
                    <Button title="Remover" onClick={() => fields.remove(index)}>X</Button>
                </Row>
            )}
        </>
    )
}

const renderContatos = ({ fields }) => {
    return (
        <>
            <Row>
                <Col>
                    <Button color="warning" style={{marginBottom:15, color:'white'}} onClick={() => fields.push({})}>Adicionar Contato</Button>
                </Col>
            </Row>
            {fields.map((cond, index) =>
                <Row key={index} style={{background: '#00000014',padding: 6,marginBottom: 15}}>
                    <Col>
                        <Field name={`${cond}.nome`} type="text" component={SimpleInput} label="Nome"/>                      
                    </Col>
                    <Col>
                        <Field name={`${cond}.email`} type="email" component={SimpleInput} label="E-mail"/>
                    </Col>
                    <Col>
                        <Field name={`${cond}.numero`} type="text" component={SimpleInput} label="Numero" {...masks.phoneMask}/>
                    </Col>
                    <Button title="Remover" onClick={() => fields.remove(index)}>X</Button>
                </Row>
            )}
        </>
    )
}

class FormAlertas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          user: null,
          locais: [], 
          pessoas: []
        };
      }

    componentDidMount(){
        if(this.props.initialValues){
            this.getDispositivos(this.props.initialValues.user);
            this.getPessoas(this.props.initialValues.user)
        }
        // getObjetos();
    }

    componentDidUpdate(){
        if(this.props.initialValues && this.state.user != this.props.initialValues.user){
            this.setState({ user: this.props.initialValues.user});   
            this.getDispositivos(this.props.initialValues.user);
            this.getPessoas(this.props.initialValues.user)
        }
    }
    
    getDispositivos(user) {
        if (!user) return;
        listDispositivos(user.id).then(({data}) => {  
            const locais  = []
            data.map(dev => {
                if (dev.active)
                    locais.push(dev.local)
            })
            this.setState({locais})
        })
    }

    getPessoas(user) {
        if (!user) return;
        listPessoas(user.id).then(({data}) => {      
            const pessoas = []
            data.map(pessoa => {
                const people = {
                    value: pessoa.codId,
                    label: pessoa.nome
                }
                if (pessoa.active)
                    pessoas.push(people)
            })
            this.setState({pessoas})
        })
    }

    render(){
        
        const { handleSubmit, pristine, submitting, invalid } = this.props;
        const { locais, pessoas } = this.state;
        
        return (
            <form onSubmit={handleSubmit}>
                <Field name="_id" type="hidden" component={HiddenInput}/>
                <Row>
                    <Col>
                        <Field name="descricao" type="text" component={SimpleInput} label="Descrição"/>
                    </Col>
                    <Col>
                        <Field name="type" options={types} component={SimpleSelect} label="Nível de alerta" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field name="estado" options={typesEstado} component={SimpleSelect} label="Estado" />
                    </Col>
                    {
                        locais.length > 0 && 
                            <Col>
                                <Field name="local" options={locais} component={SimpleSelect} label="Local" />
                            </Col>
                    }
                </Row>
                <Row>
                    {
                        pessoas.length > 0 &&
                            <Col>
                                <Field name="people" options={pessoas} component={SimpleSelect} label="Pessoa" />
                            </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <Field name="mensagem" type="text" component={SimpleInput} label="Mensagem" placeholder="Utilizada no envio de alertas para e-mail e SMS" />
                    </Col>
                </Row>

                <FieldArray name="contatos" component={renderContatos}/>

                <FieldArray name="conditions" component={renderCondicoes}/>

                <div>
                    <Button type="submit" disabled={invalid || pristine || submitting}>Salvar</Button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'FormAlertas',
    validate
  })(FormAlertas)