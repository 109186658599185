import React, { Component } from "react";

import { Card, Table, Button, ButtonGroup, Spinner } from 'reactstrap';
import { FaPen, FaLock, FaLockOpen, FaEye } from 'react-icons/fa';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";
import FiltroLista from "../../forms/filtro-lista";

import { listFranqueados, handleActiveFranqueado, contaInfo } from '../../api'

class ListaFranqueados extends Component {

    state={
        isLoading: true, 
        franquados: []
    }

    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Franqueados Cadastrados',
            button:{
                label:'Cadastrar Franqueado',
                action:()=>this.props.history.push('/dashboard/franqueado/cadastro')
            }
        })
        this.list()
    }

    list(){
        let { user } = this.props.state
        listFranqueados(user.id).then(res => {            
            this.setState({
                isLoading: false,
                franquados: res.data
            })
        })
    }

    itemClick(id) {
        this.props.history.push('/dashboard/franqueado/cadastro/'+id)
    }

    handleActive(id){
        handleActiveFranqueado(id, false).then( res => {
            this.list();
        })
    }
    
    handleRemove(id){
        handleActiveFranqueado(id, true).then( res => {
            this.list();
        })    
    }

    _accessFranqueadoView(id) {
        console.log(id);
        contaInfo(id,'franqueado').then(res => {

            if (!res.data) {
                alert('Erro ao obter informações do cliente');
                return;
            }

            let conta = res.data;

            const profile = this.props.state.user.profile
            profile.push({
                id: this.props.state.user.id,
                nome: this.props.state.user.nome,
                role: this.props.state.user.role,
            })
            let newUser = {
                profile,
                id: conta.franqueado._id,
                codId: conta.franqueado.codId,
                isMaster: conta.isMaster,
                nome: conta.franqueado.nome,
                email: conta.franqueado.email,
                role: conta.type
            }

            console.log(newUser);

            localStorage.setItem('auth',JSON.stringify(newUser))
            this.props.history.push('/dashboard')

        })
    }

    render(){
        let { franquados, isLoading }= this.state
        return(
            <Card body>
                {/* <FiltroLista onSubmit={(filtro)=>{console.log(filtro)}}/> */}
                <Table responsive>
                    <thead>
                        <th>Nome</th>
                        <th>Celular</th>
                        <th>Email</th>
                        <th></th>
                    </thead>
                    <tbody>
                    {isLoading && <Spinner color="primary" style={{ width: '5rem', height: '5rem', position:'absolute', left:'45%' }} />}
                    {franquados.map( f => (
                        <tr>
                            <td>{f.nome}</td>
                            <td>{f.responsavel?.celular}</td>
                            <td>{f.responsavel?.email}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    <Button size={'sm'} color="info" onClick={()=>this._accessFranqueadoView(f._id)}><FaEye/></Button>
                                    <Button size={'sm'} onClick={()=>this.itemClick(f._id)}><FaPen/></Button>
                                    {
                                        f.active?
                                            <Button size={'sm'} color={'success'} onClick={()=>this.handleRemove(f._id)}><FaLockOpen/></Button>
                                        :
                                            <Button size={'sm'} color={'danger'} onClick={()=>this.handleActive(f._id)}><FaLock/></Button>
                                    }                                    
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}  
                    </tbody>
                </Table>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaFranqueados);