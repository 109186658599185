
class WsClient {

  constructor (endpoint, onOpen, onMessage, onClose, onError, id) {

    this.endpoint = endpoint;
    this.onOpen = onOpen;
    this.onMessage = onMessage;
    this.onClose = onClose;
    this.onError = onError;
    this.id = id;
  }

  initialize () {
    this.wsClient = new WebSocket(this.endpoint+`?id=${this.id}`);

    this.wsClient.onopen = this.onOpen;
    this.wsClient.onmessage = this.onMessage;
    this.wsClient.onerror = this.onError;
    this.wsClient.onclose = this.onClose;
  }

  sendWebsocketMessage (event) {
    const eventJson = JSON.stringify(event);

    if (this.wsClient != undefined && this.wsClient.readyState === 1) { 
      this.wsClient.send(eventJson);
    }
  }

  close(){
    if (this.wsClient != undefined && this.wsClient.readyState === 1) { 
      this.wsClient.close(1000, 'closing ws')
    }
  }
  
}

export default WsClient;