import axios from '../../../../api'

export function create( clienteId, data ) {    
    return axios.put('/device/cadastrar/' + clienteId , data)
}

export function update(data) {
    return axios.post('/device/editar/' + data._id , data)
}

export function get(id) {
    return axios.get('/device/get/' + id)
}

export function deletePhoto(id, photo_id) {
    return axios.delete('/device/delete/photo/' + id + '/' + photo_id)
}
