import React from "react"
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';

const renderField = (props,{input}) => (
        <input {...props} {...props.input} />
)

let AuthLoginForm = props => {
    const { handleSubmit, pristine, submitting } = props
    return (
        <form onSubmit={handleSubmit}>
            <Field name="email" type="email" className="form-control" component={renderField} label="Nome da Tarefa" placeholder="Email address" autofocus required/>
            <Field name="password" type="password" className="form-control" component={renderField} label="Descrição" placeholder="Senha" required/>
            <Button type="submit" disabled={pristine || submitting} block>Entrar</Button>
        </form>
    )
}

AuthLoginForm = reduxForm({
  form: 'AuthLoginForm'
})(AuthLoginForm)

export default AuthLoginForm