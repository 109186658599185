import axios from '../../api'

export function login(email, password, type) {
    return axios.post('/conta/login/' + type, {
        email,
        password,
    })
}

export function forgotPassword(email,type) {
    return axios.put('/conta/' + type + '/recover/password', {
        email,
    })
}
