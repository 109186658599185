import { createReducer } from "reduxsauce";
import { Types } from "./actions";

const initialState = {
    nome:"",
    email:"",
    token:"",
    role:"",
};

const setUser = (state = initialState, action) => {
    return action.data || state;
};


export default createReducer(initialState, {
    [Types.SET_USER]: setUser,
});