import React, { useEffect } from 'react'
import { Field, reduxForm, FieldArray,formValueSelector } from 'redux-form';
import { connect } from "react-redux";
import { Button,Row,Col, Label } from 'reactstrap';
import { SimpleInput, HiddenInput, SimpleSelect } from '../components/FormInputs';
import { listObjetos } from '../api'
import { render } from '@testing-library/react';
import { FiPrinter } from 'react-icons/fi';

const validate = values => {
    const errors = {}
    if (!values.label) 
        errors.label = 'Obrigatório';
    if (!values.local) 
        errors.local = 'Obrigatório';
    return errors;
}

let objetos = [];
function getObjetos(){
    objetos = []
    console.log(1);
    listObjetos().then(({data})=>{
        data.map(obj => {if(obj.active) objetos.push({value:obj._id,label:obj.label})})
    })
}

const renderObjetos = ({ fields }) => {
    return (
        <>
            <Row>
                <Col>
                    <Button color="success" style={{marginBottom:15}} onClick={() => fields.push({})}>Adicionar Objeto</Button>
                </Col>
            </Row>
            <Row>
                {fields.map((cond, index) =>
                    <>
                        <Col md="6">
                            <Field name={`${cond}.objeto`} options={objetos} component={SimpleSelect} label="Objeto" />
                        </Col>
                        {/* <Col>
                            <Field name={`${cond}.position`} type="text" component={SimpleInput} label="Posição"/>
                        </Col> */}
                    </>
                )}
            </Row>
        </>
    )
}

class FormDispositivos extends React.Component{

    constructor(props){
        super(props);
        getObjetos();
    }

    render(){
        const { handleSubmit, pristine, submitting, invalid, label } = this.props
        
        return (
            <form onSubmit={handleSubmit}>
                <Field name="_id" type="hidden" component={HiddenInput}/>
                <Row>
                    <Col xs="12" md="6" lg="12"><Field name="label" type="text" placeholder="" component={SimpleInput} label="Nome" /></Col>                
                    {/* <Col xs="12" sm="6"><Field name="tipo" type="text" placeholder="" component={SimpleInput} label="Tipo" /></Col> */}
                    <Col xs="12" sm="6" lg="12"><Field name="local" type="text" placeholder="" component={SimpleInput} label="Local" /></Col>
                </Row>
                {/* <Label>Objetos:</Label>
                <div className="block-comodo" style={{marginBottom:'15px'}}>
                    <span>{label}</span>
                    <div className="comodo-objects" style={{padding:'15px',paddingBottom:0}}>
                        <Row>
                            <Col md="4"><Field name={`objetos[0].objeto`} options={objetos} component={SimpleSelect}  /></Col>
                            <Col md="4"><Field name={`objetos[1].objeto`} options={objetos} component={SimpleSelect}  /></Col>
                            <Col md="4"><Field name={`objetos[2].objeto`} options={objetos} component={SimpleSelect}  /></Col>
                            <Col md="4"><Field name={`objetos[3].objeto`} options={objetos} component={SimpleSelect}  /></Col>
                            <Col md="4"><Field name={`objetos[4].objeto`} options={objetos} component={SimpleSelect}  /></Col>
                            <Col md="4"><Field name={`objetos[5].objeto`} options={objetos} component={SimpleSelect}  /></Col>
                        </Row>
                    </div>
                </div> */}

                <div>
                    <Button type="submit" disabled={invalid || pristine || submitting}>Salvar</Button>
                </div>

            </form>
        )
    }
}


const selector = formValueSelector('FormDispositivos') // <-- same as form name



FormDispositivos = reduxForm({
    form: 'FormDispositivos',
    enableReinitialize: true,
    validate,
})(FormDispositivos)

FormDispositivos = connect(
    state => ({
        label: selector(state, 'label'),
    })
)(FormDispositivos)

export default FormDispositivos