import React, { useEffect } from 'react'
import { Field, reduxForm, FormSection,formValues } from 'redux-form';
import { Button,Row,Col } from 'reactstrap';
import { SimpleInput, HiddenInput } from '../components/FormInputs';

const validate = values => {
    const errors = {}
    if (!values.nome) 
        errors.label = 'Obrigatório';
    if (!values.idade) 
        errors.local = 'Obrigatório';
    return errors;
}

let FormPessoas = props => {

    useEffect(() => {
        if(props.initialValue)
            props.initialize(props.initialValue)
    }, [props]);

    const { handleSubmit, pristine, submitting, invalid } = props

    return (
        <form onSubmit={handleSubmit}>
            <Field name="_id" type="hidden" component={HiddenInput}/>
            <Row>
                <Col xs="12" md="6" lg="12"><Field name="nome" type="text" placeholder="" component={SimpleInput} label="Nome" /></Col>                
                <Col xs="12" sm="6" lg="4"><Field name="idade" type="text" placeholder="" component={SimpleInput} label="Idade" /></Col>
                <Col xs="12" sm="6" lg="4"><Field name="peso" type="text" placeholder="" component={SimpleInput} label="Peso" /></Col>
                <Col xs="12" sm="6" lg="4"><Field name="altura" type="text" placeholder="" component={SimpleInput} label="Altura" /></Col>
                <Col xs="12" sm="6" lg="12"><Field name="patologia" type="text" placeholder="" component={SimpleInput} label="Patologia" /></Col>
            </Row>
            <div>
                <Button type="submit" disabled={invalid || pristine || submitting}>Salvar</Button>
            </div>

        </form>
    )
}

export default reduxForm({
    form: 'FormPessoas',
    validate
  })(FormPessoas)