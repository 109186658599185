import React, { useEffect } from 'react'
import { Field, reduxForm, FormSection,formValues } from 'redux-form';
import { Button,Row,Col } from 'reactstrap';
import { SimpleInput, HiddenInput } from '../components/FormInputs';
import FormAcesso from './section_acesso';
import * as masks from './masks';

const validate = values => {
    const errors = {}
    if (!values.nome) 
        errors.nome = 'Obrigatório';
    if (!values.login) 
        errors.login = 'Obrigatório';
    if (!values.celular) 
        errors.celular = 'Obrigatório';
    if (!values.password) 
        errors.password = 'Obrigatório';
    if (!values.password_confirm || values.password != values.password_confirm ) 
        errors.password_confirm = 'As senhas são diferentes';
    return errors;
}

let FormCadastroAcessos = props => {

    useEffect(() => {
        if(props.initialValue)
            props.initialize(props.initialValue)
    }, [props]);

    const { handleSubmit, pristine, submitting, invalid } = props

    return (
        <form onSubmit={handleSubmit}>
            <Field name="_id" type="hidden" component={HiddenInput}/>
            <Field name="type" type="hidden" component={HiddenInput}/>
            <Row>
                <Col xs="12" md="12" lg="6"><Field name="nome" type="text" component={SimpleInput} label="Nome" /></Col>                
                <Col xs="12" sm="6"><Field name="celular" type="text" component={SimpleInput} label="Celular" {...masks.phoneMask} /></Col>
            </Row>
            <fieldset>
                <legend>Acesso:</legend>
                <FormAcesso/>
            </fieldset>
            <div>
                <Button type="submit" disabled={invalid || pristine || submitting}>Salvar</Button>
            </div>

        </form>
    )
}

export default reduxForm({
    form: 'FormCadastroAcessos',
    validate
  })(FormCadastroAcessos)