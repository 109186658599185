import React, { Component } from "react";
import _ from 'lodash'
import { Card, Alert, Form, FormGroup, Label, Input, Button, Col, Row,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Spinner
} from 'reactstrap';
import FormDispositivos from '../../forms/form-cadastro-dispositivos';
import { FaTrash, FaDownload} from 'react-icons/fa';
import Switch from "react-switch";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { create, update, get, deletePhoto } from './api'

import {urlStorage} from './../../../../api'

import WsClient from '../../../../helpers/ws-client';
import { type_people_status } from '../../../../helpers/types';
import JSZip from "jszip";
import saveAs from './../../../../../node_modules/jszip/vendor/FileSaver.js'
const zip = new JSZip();
class CadastroGrupoAtividades extends Component {
    
    state ={
        errorMsg: null,
        successMsg: null,
        successMsgCapture: null,
        initialValue: null,
        photos:[],
        activeIndex: [0,0,0,0,0,0,0],
        animating: false,
        timeoutSeconds: null, 
        cancelTimeout: null,
        isLoading: false,
    }
    
    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Cadastro de Dispositivos',
        })
        
    }

    componentDidMount(){
        const { id } = this.props.match.params;
        let { user } = this.props.state;
        if (id){
            this.getInfo();
        }
        const wssEndpoint = 'wss://anglis.com.br/myws';
        // const wssEndpoint = 'ws://localhost:8888';
        this.onWsOpened = this.onWsOpened.bind(this);
        this.onWsMessageReceived = this.onWsMessageReceived.bind(this);
        this.onWsClosed = this.onWsClosed.bind(this);
        this.onWsError = this.onWsError.bind(this);        
        
        this.wsClient = new WsClient(
            wssEndpoint,
            this.onWsOpened,
            this.onWsMessageReceived,
            this.onWsClosed,
            this.onWsError,
            user.id
        );

        this.wsClient.initialize();

    }

    getInfo(){
        const { id } = this.props.match.params;
        get(id).then(res => {
            if (res.data) {
                let objetos = [];
                res.data.objetos.map(obj => objetos[obj.position] = {objeto:obj.objeto._id,position:obj.position})
                const photos = res.data.photos;
                var grouped = _.mapValues(_.groupBy(photos, 'pose_label'),
                    clist => clist.map(photo => _.omit(photo, '')));
                var result = Object.entries(grouped)
                this.setState({initialValue: {...res.data, objetos}, photos: result,});
            }

        })
    }

    // ws region
    onWsOpened(){ 
        console.log('websocket opened'); 
    };

    onWsMessageReceived(eventJson){ 
        // console.log('websocket onWsMessageReceived', eventJson); 
        const event = JSON.parse(eventJson.data);
        const eventType = event.type;            
        const data = event.data;            
        switch (eventType) {
            case 'NOTIFICATION': {
                // this._getUserAlertas( this.props.state.user.id )
            } break;
            
            case 'PEOPLE_STATUS': {
                // this.setState({people:data})
            } break;

            default: {}
        }
    };

    onWsClosed(){ 
        console.log('websocket closed'); 
    };

    onWsError(error){ 
        console.log('websocket onWsError', error); 
    };
    // ws region

    sendCapturePhotoRequest(){
        const { user } = this.props.state;
        const { isLoading, timeoutSeconds, cancelTimeout } = this.state;
        let timelapse = true;
        clearTimeout(cancelTimeout)
        if(isLoading) {
            timelapse = false;
            this.setState({successMsgCapture: "Comando enviado. Buscando novas imagens..."})
            setTimeout(() =>{
                this.setState({successMsgCapture: false})
                this.getInfo();
            }, 3000)
        }else{
            const  cancelTimeout =setTimeout(() => {
                this.sendCapturePhotoRequest();
            }, timeoutSeconds * 1000 || 10000 )
            this.setState({cancelTimeout})
        }   
        this.setState({isLoading: !isLoading})
        this.wsClient.sendWebsocketMessage({
            type: 'REQUEST_CAPTURE_DEVICE',
            data: {
                clienteCodID: this.state.initialValue.mac,
                device: this.state.initialValue.codId, 
                pose: this.state.selectedPose,
                timelapse
            }
        });
    }

    downloadAllImages(){
        const { initialValue } = this.state
        const photos = initialValue.photos;
        photos.map((p, index) =>{                    
            zip.file(p.pose_label + "_" + index + '.png', p.imagemBase64.replace("data:image/png;base64,", ''), {base64: true});
        })
        zip.generateAsync({type: 'blob'})
        .then(function(content) {
            console.log(initialValue)
            saveAs(content, initialValue.local+ initialValue.label +"_images.zip");
            photos.map((p, index) =>{              
                zip.remove(p.pose_label + "_" + index + '.png');
            })
        });
    }

    next(array, index){
        if (this.state.animating) return;
        let { activeIndex } = this.state;
        activeIndex[index] = activeIndex[index] === array.length - 1 ? 0 : activeIndex[index] + 1;
        this.setState({activeIndex})
    }

    previous(array, index){
        if (this.state.animating) return;
        let { activeIndex } = this.state;
        activeIndex[index] = activeIndex[index] === 0 ? array.length - 1 : activeIndex[index] - 1;
        this.setState({activeIndex})
    }

    goToIndex(newIndex, index){
        if (this.state.animating) return;
        let { activeIndex } = this.state;
        activeIndex[index] = newIndex
        this.setState({activeIndex})
    }

    deleteImg(item, index, indexMaster){
        const { id } = this.props.match.params;
        let { photos } = this.state;
        photos[indexMaster][1].splice(index, 1);
        this.setState({photos})
        deletePhoto(id, item._id)
        if(item.file){
            this.wsClient.sendWebsocketMessage({
                type: 'DELETE_POSES',
                data: {
                    clienteCodID: item.mac || '',
                    images:[ item.file ],
                    pose: item.pose
                }
            });
        }
    }

    slides = (items, indexMaster) => {
        return(
            items.map((item, index) => {
                return(
                    <CarouselItem
                        onExiting={() => this.setState({animating: true})}
                        onExited={() => this.setState({animating: false})}
                        key={item._id + index}
                    >
                        <img src={item.imagemBase64} style={{width: '100%', objectFit: 'contain',}} />
                        <CarouselCaption captionText={item.pose_label}  />
                        <div style={{position: 'absolute', top: 10, right: 10, zIndex: 10,}} >
                            <Button size={'sm'} color={'primary'} download="" href={item.imagemBase64}><FaDownload/></Button> 
                            <Button size={'sm'} color={'danger'} onClick={()=>this.deleteImg(item, index, indexMaster)} style={{marginLeft:5}}><FaTrash/></Button> 
                        </div>
                    </CarouselItem>
                )
            }) 
        )
    };

    _submit = (data) => {

        let objs = []
        // data.objetos.map( (obj,i) => {
        //     if (obj.objeto) objs.push({objeto:obj.objeto,position:i})
        // })
        data.objetos = objs;

        this.setState({errorMsg: null, successMsg: null})
        let clienteId = this.props.state.user.id;
        if(!data._id){
            create(clienteId,data).then(res => {
                if(res.data && res.data.error){
                    console.log(res.data.error)
                    this.setState({errorMsg:res.data.error})
                }else{
                    this.setState({successMsg:"Cadastro realizado com sucesso. Redirecionando..." })
                    setTimeout(()=>this.props.history.goBack(),2000)
                }
            })
        }else{
            update({
                _id: data._id,
                label: data.label,
                type: data.label,
                local: data.local,
                objetos: data.objetos
            }).then(res => {
                if(res.data && res.data.error){
                    console.log(res.data.error)
                    this.setState({errorMsg:res.data.error})
                }else{
                    this.setState({successMsg:"Atualizado com sucesso. Redirecionando..." })
                    setTimeout(()=>this.props.history.goBack(),2000)
                }
            })
        }
    }    

    render(){
        const { errorMsg, successMsg, initialValue, timeoutSeconds, selectedPose, isLoading, successMsgCapture } = this.state
        return(
            <>
            <Card body>
                <FormDispositivos onSubmit={(data) => this._submit(data)} initialValues={initialValue}/>
                {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
                {successMsg && <Alert color="success">{successMsg}</Alert>}
            </Card>
            <Card body>
                <label>Capturas por pose</label>                
                <Form>
                    <Row form>
                        <Col md={3}>
                            <FormGroup >
                                <Input type="select" name="select" id="exampleSelect" onChange={(e) => this.setState({selectedPose: e.target.value})  }>
                                    <option>Selecione a pose</option>
                                    {type_people_status.map(p => {
                                        return <option value={p.value}>{p.label}</option>
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup >
                                <Input  min={1} max={100} placeholder='timeout ( 10 segundos )' type="number" name="timeoutSeconds" id="timeoutSeconds" onChange={(e) => this.setState({timeoutSeconds: e.target.value})}/>  
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Switch 
                                    onChange={() => this.sendCapturePhotoRequest()} 
                                    checked={isLoading} 
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                />
                                {isLoading && <Spinner color='success' style={{marginLeft: 10}}/>}
                            </FormGroup>
                        </Col>
                    </Row>
                    {successMsgCapture && <Alert color="success">{successMsgCapture}</Alert>}
                </Form>
            </Card>
            <Card body>
                <Button color={'primary'} onClick={() => this.downloadAllImages()}>Baixar todas as imagens<FaDownload/></Button>
                {
                    this.state.photos.map( (photo, index) => {
                        return(
                            <>
                                <label>{photo[0]}</label>
                                <Carousel
                                    activeIndex={this.state.activeIndex[index]}
                                    next={() => this.next(photo[1], index)}
                                    previous={() => this.previous(photo[1], index)}
                                >
                                    <CarouselIndicators items={photo[1]} activeIndex={this.state.activeIndex} onClickHandler={(i) => this.goToIndex(i, index)} />
                                    {this.slides(photo[1], index)}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => this.previous(photo[1], index)} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => this.next(photo[1], index)} />
                                </Carousel>
                            </>
                        )
                    })
                }
            </Card>
            <Card body>
                <label>Imagem atual:</label>
                <div style={{width: 640, height:480}}>
                    {initialValue && <img src={initialValue.imagem?.length > 10000? initialValue.imagem : urlStorage+initialValue.imagem} style={{width: '100%', height:'100%',objectFit: 'contain'}} />}
                </div>
            </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CadastroGrupoAtividades);
