import axios from '../../../../api'

export function create( clienteId, data ) {    
    return axios.put('/alerta_configs/cadastrar/' + clienteId, data)
}

export function update(data) {
    return axios.post('/alerta_configs/editar/' + data._id, data)
}

export function get(id) {
    return axios.get('/alerta_configs/get/' + id)
}
