import React from 'react';
import { FormGroup,Label,Input, Button } from 'reactstrap';

export const SimpleInput = ({input,label,type,placeholder,meta: { touched, error, warning },children}) => (
    <FormGroup>
        {label ? <Label>{label}:</Label> : null}
        <div>
            <Input className={ touched && error ? 'input-validation-error':''} {...input} placeholder={placeholder} type={type}>{children}</Input>
            {touched && ((error && <small className="msg-validation-error">{error}</small>) || (warning && <small className="warning-input-msg">{warning}</small>))}
        </div>
    </FormGroup>
)

export const SimpleSelect = ({input,label,type,options = [],meta: { touched, error, warning }}) => (
    <FormGroup>
        {label ? <Label>{label}:</Label> : null}
        <div>
            <Input {...input} type={'select'}>
                <option value={""}>Todos</option>
                {
                    // ['option 1','option 2','option 3'] || [{label: 'option 1', value: 'value 1'},{label: 'option 1', value: 'value 2'}]
                    options.map(opt => {
                        return <option value={ (opt.value == 0 || opt.value) ? opt.value : opt }>{ opt.label || opt }</option>
                    })
                }
            </Input>
            {touched && ((error && <small>{error}</small>) || (warning && <small>{warning}</small>))}
        </div>
    </FormGroup>
) 

export const HiddenInput = ({input}) => (
    <Input {...input} type="hidden" />
)

export const FieldEstados = ({input,label,type,meta: { touched, error, warning }}) => (
    <FormGroup>
        <Label>{label}:</Label>
        <div>
            <Input {...input} type={'select'}>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
            </Input>
            {touched && ((error && <small>{error}</small>) || (warning && <small>{warning}</small>))}
        </div>
    </FormGroup>
) 
export class FieldFileInput  extends React.Component{
    constructor(props) {
      super(props)
      this.onChange = this.onChange.bind(this)
    }
  
    onChange(e) {
      const { input: { onChange } } = this.props
      onChange(e.target.files[0])
    }
  
    render(){
        const { input: { value } } = this.props
        const {input,label, required, meta, } = this.props  //whatever props you send to the component from redux-form Field
        return(
            <FormGroup>
                <Label>{label}:</Label>
                <div>
                    <input
                        type='file'
                        accept='.jpg, .png, .jpeg'
                        onChange={this.onChange}
                    />
                </div>
            </FormGroup>
        )
    }
}