import axios from '../../../../api'

export function create(admin, data) {    
    return axios.put('/franqueado/cadastrar/' + admin, data)
}

export function update(data) {
    return axios.post('/franqueado/editar/' + data._id , data)
}

export function get(id) {
    return axios.get('/franqueado/listar/detalhe/' + id)
}
