import React from 'react';
import { Field } from 'redux-form';
import { SimpleInput } from '../components/FormInputs';
import { Row,Col } from 'reactstrap';

export default function FormAcesso () {

    return(
        <Row>
            <Col xs="12" md="12" lg="4"><Field name="login" type="email" component={SimpleInput} label="Email" /></Col>
            <Col xs="12" md="6" lg="4"><Field name="password" type="password" component={SimpleInput} label="Senha" /></Col>
            <Col xs="12" md="6" lg="4"><Field name="password_confirm" type="password" component={SimpleInput} label="Confirme a Senha" /></Col>
        </Row>
    )
}