import React, { Component } from "react";

import { Card, Table, Button, ButtonGroup, Spinner } from 'reactstrap';
import { FaPen, FaLock, FaLockOpen, FaTrash, FaCheck } from 'react-icons/fa';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as PageActions } from "../../../../stores/modules/PageConfig/actions";

import { listPessoas, pessoasToggle } from '../../api'

class ListaControleAcesso extends Component {

    state={
        isLoading: true, 
        list: []
    }

    constructor(props){
        super(props)
        
        this.props.setPageSettings({
            title:'Pessoas cadastrados',
            button:{
                label:'Novo',
                action:()=>this.props.history.push('/dashboard/pessoas/cadastro')
            }
        })

        this._listPessoas();
    }

    _listPessoas() {
        let { user } = this.props.state
        listPessoas(user.id).then(res => {      
            console.log(res)      
            this.setState({
                isLoading: false,
                list: res.data
            })
        })
    }

    _itemEdit(id) {
        this.props.history.push('/dashboard/pessoas/cadastro/'+id)
    }

    _itemPessoasToggle(active,id) {
        let status = active ? 'desativar' : 'ativar';  
        pessoasToggle(status,id).then(res => {
            this._listPessoas();
        })
    }

    render(){
        let { list, isLoading }= this.state
        return(
            <Card body>
                <Table responsive>
                    <thead>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Idade</th>
                        <th></th>
                    </thead>
                    <tbody>
                    {isLoading && <Spinner color="primary" style={{ width: '5rem', height: '5rem', position:'absolute', left:'45%' }} />}
                    {console.log(list)}
                    {list.map( l => (
                        <tr>
                            <td>{l.codId}</td>
                            <td>{l.nome}</td>
                            <td>{l.idade}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    <Button size={'sm'} onClick={()=>this._itemEdit(l._id)}><FaPen/></Button>
                                    {
                                        l.active ? 
                                        <Button size={'sm'} color={'success'} onClick={()=>this._itemPessoasToggle(l.active,l._id)}><FaCheck/></Button> :
                                        <Button size={'sm'} color={'danger'} onClick={()=>this._itemPessoasToggle(l.active,l._id)}><FaTrash/></Button> 
                                    }
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}  
                    </tbody>
                </Table>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({state});  
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...PageActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaControleAcesso);