import styled from 'styled-components';
import useroverlay from './images/overlay.png';

export const TopbarWrapper  = styled.div`
    background-color: #ffffff;
    padding: 0 80px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    max-height:70px;
`;

export const MainContentWrapper = styled.div`
    display:flex;
    background-color: #f2f5f7;
`;

export const MainContentInner = styled.div`
    display:flex;
    margin-top: -70px;
    width: 100%;
    align-items: flex-start;
`;

export const PageContent = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 60px;
`;

export const UserHeaderWrapper = styled.div`
    display:flex;
    // margin-top:70px;
    padding-bottom:70px;
    background-image: url(${useroverlay});
    background-size: cover;
    background-position: center center;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31,35,60,.8);
    box-shadow: inset 0 0 0 2000px rgba(31,35,60,.8);
`;